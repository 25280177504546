import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'
import useMediaQuery from '../components/Hooks/MatchMedia'
import FoundationButton from '../components/UI/snow-leopard-button'

const SnowLeopard = ({ data, location }) => {
  const { leopard } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const mobile = useMediaQuery('(max-width: 768px)')

  return (
    <Layout
      location={typeof window !== 'undefined' && location}
      crumbLabel="Wild Cat"
    >
      <GatsbySeo
        title="Wild Cat Rescue Fund"
        description="The majestic Snow Leopard is more than just a symbol to us."
      />
      <GridContent
        id="snow-leopard"
        hide="true"
        layout="--center-4 fullscreen"
        mode={leopard.mode}
        background={mobile ? leopard.image_mobile : leopard.image}
      >
        <h4>{leopard.section}</h4>
        <h1>{leopard.title}</h1>
        <p className="markdown">{leopard.body}</p>
        <FoundationButton button={leopard.button} />
      </GridContent>
    </Layout>
  )
}

export default SnowLeopard

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "snow" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              leopard {
                body
                button {
                  label
                  link
                }
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                image_mobile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                mode
                section
                title
              }
            }
          }
        }
      }
    }
  }
`
